import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import store from '../../store/store';
// import Header from './header/Header';
// import Sidebar from './sidebar/Sidebar';
// import Menu from './sidebar/Menu';
// import Footer from './footer/Footer';
import { AxiosUser, AxiosErrorHandling } from '../../connection/Axios'
import * as IconsBi from "react-icons/bi";
import PropagateLoader from "react-spinners/PropagateLoader";
import Header from './../header/Index'
import Footer from './../footer/Index'
import { toast } from 'react-toastify';


import { Row, Alert } from 'react-bootstrap';


function Protected(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const state = store.getState()
    // console.log('state:',state)
    const dispatch = useDispatch();

    const [redirectTo, setRedirectTo] = useState(false)
    const [hasError, setHasError] = useState(false)
    const [target, setTarget] = useState(false)
    const [sidebar, setSidebar] = useState(true)
    let [color, setColor] = useState("#ffffff");

    const [re_render, setRe_render] = useState(1);
    const updateStateForReRender = () => {
        setRe_render(re_render + 1)
    }

    // useEffect(() => {
    //     console.log('useEffect - state:',state)
    // }, [state])

    useEffect(() => {
        // console.log('Protected - new state:', state.loading)
    }, [dispatch])

    useEffect(() => {
        // console.log('state:',state,state?.logedIn)
        if(state?.logedIn) {

        } else {
            // if (localStorage.getItem('token')) {
            //     console.log('HERE')
            //     let token = localStorage.getItem('token')
            //     if(token !== null && token !== undefined) {
            //         AxiosUser.post('/check-token', JSON.stringify({token: token, type: 'audible'}))
            //         .then(async (res) => {
            //             let data = res.data
            //             let userInfo = {
            //                 id: data.data.id,
            //                 code: data.data.code,
            //                 gender: data.data.gender,
            //                 name: data.data.name,
            //                 last_name: data.data.last_name,
            //                 email: data.data.email,
            //                 mobile: data.data.mobile,
            //                 national_id: data.data.national_id,
            //                 avatar: data.data.avatar,
            //                 token: token,
            //                 cart: data.data.cart,
            //             }
            //             store.dispatch({ type: 'LOGIN', payload: userInfo });
            //             // store.dispatch({ type: 'SET_LANG', payload: {lang: localStorage.getItem('lang')} });
            //         })
            //         .catch((err) => {
            //             navigate('/login')
            //         })
            //     }
            // } else {
            //     toast.error('لطفا وارد حساب کاربریتان شوید.X')
            //     navigate('/login')
            // }
        }
    }, [])

    const sidebarToggle = () => {
        setSidebar(!sidebar)
    }

    if(state?.logedIn) {
        return (
            <>
                {
                    <>
                        <Header {...props} />
                        
                        <main className="main pages">
                            {/* <div className="container"> */}
                            <Row>
                            {
                                React.cloneElement(props.children, {re_render: re_render})
                            }
                            </Row>
                            {/* </div> */}
                        </main>
                        <Footer state={state} />
                    </>
                }
            </>
        )
    } else if (!localStorage.getItem('token')) {
        return <Navigate to="/login" replace />
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
}

export default connect(mapStateToProps)(Protected);
