import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import store from './../../store/store';
import * as IconsBi from 'react-icons/bi';
import { ToastContainer, toast } from 'react-toastify';
import Header from './../header/Index'
import Footer from './../footer/Index'
import { Row } from 'react-bootstrap';

function Public(props) {
    const state = store.getState()
    // console.log('Public - state: ',state)
    // console.log('props.children: ',props.children)

    useEffect(() => {

    }, [])

    useEffect(() => {
        // console.log('useEffect - state: ',state)
    }, [state])

    const [re_render, setRe_render] = useState(1);
    const updateStateForReRender = () => {
        setRe_render(re_render + 1)
    }

    return (
        <>
            <Header {...props} />
            <main className="main pages">
                {/* <div className="container" style={{textAlign:'justify',backgroundColor:'red'}}> */}
                <Row className="mx-3" style={{textAlign:'justify'}}>
                    {
                        React.cloneElement(props.children, {re_render: re_render})
                    }
                </Row>
                {/* </div> */}
            </main>
            <Footer state={state} />
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        number: state.number,
        user: state.user
    };
}

export default connect(mapStateToProps)(Public);
