import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import store from './../../store/store'
import * as IconsBi from 'react-icons/bi';

function Header_Middle(props) {
    const state = store.getState()
    
    useEffect(() => {

    }, [])

    return (
        <>
            <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
                <div className="container">
                    <div className="header-wrap">
                        <div className="logo logo-width-1">
                            <Link to="/home">
                                <img src="/assets/imgs/theme/logo.png" alt="logo" />
                            </Link>
                        </div>
                        <div className="header-right">
                            <div className="search-style-2">
                                <form action="#">
                                    <select className="select-active">
                                        <option>همه دسته ها</option>
                                        <option>آرایشی</option>
                                        <option>بهداشتی</option>
                                        <option>بانوان</option>
                                        <option>مردان</option>
                                    </select>
                                    <input type="text" placeholder="جستجو کنید ..." />
                                </form>
                            </div>
                            <div className="header-action-right">
                                <div className="header-action-2">
                                    <div className="header-action-icon-2">
                                        <a href="shop-wishlist.html">
                                            <img className="svgInject" alt="Nest" src="/assets/imgs/theme/icons/icon-heart.svg" />
                                            <span className="pro-count blue">6</span>
                                        </a>
                                    </div>
                                    <div className="header-action-icon-2">
                                        <a className="mini-cart-icon" href="shop-cart.html">
                                            <img alt="Nest" src="/assets/imgs/theme/icons/icon-cart.svg" />
                                            <span className="pro-count blue">2</span>
                                        </a>
                                        <div className="cart-dropdown-wrap cart-dropdown-hm2">
                                            <ul>
                                                <li>
                                                    <div className="shopping-cart-img">
                                                        <a href="shop-product-right.html"><img alt="Nest" src="/assets/imgs/shop/thumbnail-3.jpg" /></a>
                                                    </div>
                                                    <div className="shopping-cart-title">
                                                        <h4><a href="shop-product-right.html">Daisy Casual Bag</a></h4>
                                                        <h4><span>1 × </span>$800.00</h4>
                                                    </div>
                                                    <div className="shopping-cart-delete">
                                                        <a href="#"><i className="fi-rs-cross-small"></i></a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="shopping-cart-img">
                                                        <a href="shop-product-right.html"><img alt="Nest" src="/assets/imgs/shop/thumbnail-2.jpg" /></a>
                                                    </div>
                                                    <div className="shopping-cart-title">
                                                        <h4><a href="shop-product-right.html">Corduroy Shirts</a></h4>
                                                        <h4><span>1 × </span>$3200.00</h4>
                                                    </div>
                                                    <div className="shopping-cart-delete">
                                                        <a href="#"><i className="fi-rs-cross-small"></i></a>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="shopping-cart-footer">
                                                <div className="shopping-cart-total">
                                                    <h4>Total <span>$4000.00</span></h4>
                                                </div>
                                                <div className="shopping-cart-button">
                                                    <a href="shop-cart.html" className="outline">View cart</a>
                                                    <a href="shop-checkout.html">Checkout</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="header-action-icon-2">
                                        <a>
                                            <img className="svgInject" alt="Nest" src="/assets/imgs/theme/icons/icon-user.svg" />
                                        </a>
                                        {/* <Link to="/login"><span className="lable ml-0">Account</span></a> */}
                                        <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                                            <ul>
                                                {
                                                    (state.user === null || state.user?.id === null)
                                                    ?
                                                    <>
                                                        <li>
                                                            <Link to="/login">
                                                                <IconsBi.BiLogIn className="mr-10"/>
                                                                ورود
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/register">
                                                                <IconsBi.BiUserPlus className="mr-10"/>
                                                                ایجاد حساب کاربری
                                                            </Link>
                                                        </li>
                                                    </>
                                                    :
                                                    <>
                                                        <li>
                                                            <Link to="/user/profile">
                                                                <IconsBi.BiUser className="mr-10"/>
                                                                حساب کاربری من
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/user/tracking">
                                                                <IconsBi.BiTargetLock className="mr-10"/>
                                                                پیگیری سفارش
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/user/orders">
                                                                <IconsBi.BiFile className="mr-10"/>
                                                                سفارشهای من
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/user/favorites">
                                                                <IconsBi.BiHeart className="mr-10"/>
                                                                علاقمندی های من
                                                            </Link>
                                                        </li>
                                                        {/* <li>
                                                            <Link to="/user/settings">
                                                                <IconsBi.BiCog className="mr-10"/>
                                                                تنظیمات
                                                            </Link>
                                                        </li> */}
                                                        <li>
                                                            <Link to="/logout">
                                                                <IconsBi.BiLogOutCircle className="mr-10"/>
                                                                خروج
                                                            </Link>
                                                        </li>
                                                    </>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        number: state.number,
        user: state.user
    };
}

export default connect(mapStateToProps)(Header_Middle);