import { LOGIN, LOGOUT, START_BASELOADING, STOP_BASELOADING, START_LOADING, STOP_LOADING, SET_LANG, HOME, USER_UPDATE, CART_UPDATE, FAVORITES_UPDATE } from './actions';

const initialState = {
    baseLoading: null,
    loading: true,
    logedIn: false,
    lang: 'en',
    user: null
    // user: {
    //     id: null,
    //     name: null,
    //     last_name: null,
    //     email: null,
    //     avatar: null,
    //     token: null,
    // }
};

function reducer(state = initialState, action) {
    // console.log('action:',action)
    switch(action.type) {
        case LOGIN:
            // console.log('LOGIN',action.payload)
            localStorage.setItem('token',action.payload.token)
            return {
                ...state,
                logedIn: true,
                user: {
                    id: action.payload.id,
                    code: action.payload.code,
                    gender: action.payload.gender,
                    name: action.payload.name,
                    last_name: action.payload.last_name,
                    email: action.payload.email,
                    mobile: action.payload.mobile,
                    national_id: action.payload.national_id,
                    avatar: action.payload.avatar,
                    token: action.payload.token,
                    cart: action.payload.cart,
                    favorites: action.payload.favorites,
                    orders: action.payload.orders,
                }
            };
        case LOGOUT:
            localStorage.removeItem('token')
            // console.log('LOGOUT - state:',state)
            return {
                ...state,
                logedIn: false,
                user: null
            };
        case START_BASELOADING:
            return {
                ...state,
                baseLoading: true
            };
        case STOP_BASELOADING:
            return {
                ...state,
                baseLoading: false,
                loading: false
            };
        case START_LOADING:
            return {
                ...state,
                loading: true
            };
        case STOP_LOADING:
            return {
                ...state,
                loading: false
            };
        // case SET_LANG:
        //     localStorage.setItem('lang',action.payload.lang)
        //     return {
        //         ...state,
        //         lang: action.payload.lang
        //     };
        case HOME:
            // console.log('(case HOME) - action:',action)
            return {
                ...state,
                home: action.payload.home
            };
        case USER_UPDATE:
            return {
                ...state,
                user: {
                    ...state.user,
                    id: action.payload.id,
                    code: action.payload.code,
                    gender: action.payload.gender,
                    name: action.payload.name,
                    last_name: action.payload.last_name,
                    email: action.payload.email,
                    mobile: action.payload.mobile,
                    national_id: action.payload.national_id,
                    avatar: action.payload.avatar,
                }
            };
        case CART_UPDATE:
            // console.log('CART_UPDATE',action.payload)
            return {
                ...state,
                user: {
                    ...state.user,
                    cart: action.payload.cart,
                }
            };
        case FAVORITES_UPDATE:
            return {
                ...state,
                user: {
                    ...state.user,
                    favorites: action.payload.favorites,
                }
            };
        default:
            return state;
    }
}

export default reducer;