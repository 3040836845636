export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const START_BASELOADING = 'START_BASELOADING';
export const STOP_BASELOADING = 'STOP_BASELOADING';
export const START_LOADING = 'START_LOADING';
export const STOP_LOADING = 'STOP_LOADING';
export const SET_LANG = 'SET_LANG';
export const HOME = 'HOME';
export const USER_UPDATE = 'USER_UPDATE';
export const CART_UPDATE = 'CART_UPDATE';
export const FAVORITES_UPDATE = 'FAVORITES_UPDATE';