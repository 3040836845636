import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router'
import { connect } from 'react-redux';
import store from './../../store/store'
import * as IconsBi from 'react-icons/bi';
import MiniCart from '../cart/MiniCart'
import { Row } from 'react-bootstrap';

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

function Header_Bottom(props) {
    const state = store.getState()
    const navigate = useNavigate();
    // console.log('state:',state)
    const [favoritesCount, setFavoritesCount] = useState(0);
    const [navbarStatus, setNavbarStatus] = useState(false);

    useEffect(() => {
        setFavoritesCount(state?.user?.favorites?.length)
    }, [state?.user?.favorites])

    useEffect(() => {
        if(navbarStatus)
            setNavbarStatus(!navbarStatus)
    }, [navigate])


    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        document.addEventListener("scroll", () => {
            const scrollCheck = window.scrollY >= 80;
            if (scrollCheck !== scroll) {
                setScroll(scrollCheck);
            }
        });
    });

    const expand = 'lg'
    // const expand = 'xxl'

    return (
        <>
            <div
                className={
                    scroll
                        ? "header-bottom header-bottom-bg-color sticky-bar stick"
                        : "header-bottom header-bottom-bg-color sticky-bar"
                }
                style={{padding:'0'}}
            >
                {/* <div className="container"> */}
                {/* <div className="mx-2"> */}
                    <div className="header-wrap1 position-relative">
                        {/* <div className="logo1 logo-width-1 d-block">
                            <Link to="/home">
                                <img src="/assets/imgs/theme/logo_name.png" alt="logo" />
                            </Link>
                        </div> */}
                        <div className="header-right1">
                            {/* <div className="header-nav d-lg-flex"> */}
                            <Row>
                            <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-6 col-sm-6 col-xs-6 col-8">
                                <Navbar expand={expand} className="mt-1 mb-1"
                                    collapseOnSelect
                                    onToggle={setNavbarStatus}
                                    expanded={navbarStatus}
                                >
                                    <Container fluid>
                                        <Navbar.Brand href="/home">
                                            <img src="/assets/imgs/theme/logo_name.png" alt="logo" style={{width:'150px'}} />
                                        </Navbar.Brand>
                                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                                        <Navbar.Offcanvas
                                            id={`offcanvasNavbar-expand-${expand}`}
                                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                            placement="start"
                                        >
                                        <Offcanvas.Header closeButton>
                                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                                <img src="/assets/imgs/theme/logo_name.png" alt="logo" style={{width:'150px'}} />
                                            </Offcanvas.Title>
                                        </Offcanvas.Header>
                                        <Offcanvas.Body>
                                            <Nav className="justify-content-start flex-grow-1 pe-3">
                                            {
                                                state?.home?.header_menu?.map((item,index) => {
                                                    return (
                                                        <Nav.Link key={index} as={Link} to={item.link}>
                                                            {/* <Link key={index} to={item.link}> */}
                                                                {item.title}
                                                            {/* </Link> */}
                                                        </Nav.Link>
                                                    )
                                                })
                                            }
                                            <hr/>
                                            {
                                                (state.user === null || state.user?.id === null)
                                                ?
                                                    // <Nav.Link className="d-block d-lg-none">
                                                        <Link to="/login">
                                                            ورود
                                                        </Link>
                                                    // </Nav.Link>
                                                :
                                                <>
                                                    {/* <Nav.Link className="d-block d-lg-none"> */}
                                                        <Link className="d-block d-lg-none" to="/user/profile">
                                                            <IconsBi.BiUser className="mr-10"/>
                                                            حساب کاربری من
                                                        </Link>
                                                    {/* </Nav.Link> */}
                                                    <Link className="d-block d-lg-none" to="/user/cart">
                                                        <IconsBi.BiBasket className="mr-10"/>
                                                        سبد خرید
                                                    </Link>
                                                    {/* <Nav.Link className="d-block d-lg-none"> */}
                                                        <Link className="d-block d-lg-none" to="/user/tracking">
                                                            <IconsBi.BiTargetLock className="mr-10"/>
                                                            پیگیری سفارش
                                                        </Link>
                                                    {/* </Nav.Link> */}
                                                    {/* <Nav.Link className="d-block d-lg-none"> */}
                                                        <Link className="d-block d-lg-none" to="/user/orders">
                                                            <IconsBi.BiFile className="mr-10"/>
                                                            سفارشهای من
                                                        </Link>
                                                    {/* </Nav.Link> */}
                                                    {/* <Nav.Link className="d-block d-lg-none"> */}
                                                        <Link className="d-block d-lg-none" to="/user/favorites">
                                                            <IconsBi.BiHeart className="mr-10"/>
                                                            علاقمندی های من
                                                        </Link>
                                                    {/* </Nav.Link> */}
                                                    {/* <Nav.Link className="d-block d-lg-none"> */}
                                                        <Link className="d-block d-lg-none" to="/logout">
                                                            <IconsBi.BiLogOutCircle className="mr-10"/>
                                                            خروج
                                                        </Link>
                                                    {/* </Nav.Link> */}
                                                </>
                                            }
                                            </Nav>
                                        </Offcanvas.Body>
                                        </Navbar.Offcanvas>
                                    </Container>
                                </Navbar>
                                
                            </div>
                            {/* <div className="header-action-right"> */}
                            {/* <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 d-none d-lg-block"> */}
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-6 col-4">
                                <div className="flex-container header-action-2" dir="ltr">
                                    <div className="flex-item header-action-icon-2 me-3">
                                        <Link>
                                            <IconsBi.BiUser className="mr-10"/>
                                        </Link>
                                        <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown" dir="rtl">
                                            <ul>
                                                {
                                                    (state.user === null || state.user?.id === null)
                                                    ?
                                                    <>
                                                        <li>
                                                            <Link to="/login">
                                                                <IconsBi.BiLogIn className="mr-10"/>
                                                                ورود
                                                            </Link>
                                                        </li>
                                                        {/* <li>
                                                            <Link to="/register">
                                                                <IconsBi.BiUserPlus className="mr-10"/>
                                                                ایجاد حساب کاربری
                                                            </Link>
                                                        </li> */}
                                                    </>
                                                    :
                                                    <>
                                                        <li>
                                                            <Link to="/user/profile">
                                                                <IconsBi.BiUser className="mr-10"/>
                                                                حساب کاربری من
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/user/tracking">
                                                                <IconsBi.BiTargetLock className="mr-10"/>
                                                                پیگیری سفارش
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/user/orders">
                                                                <IconsBi.BiFile className="mr-10"/>
                                                                سفارشهای من
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to="/user/favorites">
                                                                <IconsBi.BiHeart className="mr-10"/>
                                                                علاقمندی های من
                                                            </Link>
                                                        </li>
                                                        {/* <li>
                                                            <Link to="/user/settings">
                                                                <IconsBi.BiCog className="mr-10"/>
                                                                تنظیمات
                                                            </Link>
                                                        </li> */}
                                                        <li>
                                                            <Link to="/logout">
                                                                <IconsBi.BiLogOutCircle className="mr-10"/>
                                                                خروج
                                                            </Link>
                                                        </li>
                                                    </>
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="flex-item header-action-icon-2">
                                        <MiniCart />
                                    </div>
                                    <div className="flex-item header-action-icon-2">
                                        <Link to={(state.user === null || state.user?.id === null) ? "/login" : "/user/favorites"}>
                                            <IconsBi.BiStar className="mr-10" />
                                            {
                                                favoritesCount > 0 &&
                                                <span className="pro-count blue">{favoritesCount}</span>
                                            }
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            </Row>
                        </div>
                    </div>
                {/* </div> */}
                {/* </div> */}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        number: state.number,
        user: state.user
    };
}

export default connect(mapStateToProps)(Header_Bottom);
