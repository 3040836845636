import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import * as IconsBi from 'react-icons/bi';
import Header_Top from './Header_Top';
import Header_Middle from './Header_Middle';
import Header_Bottom from './Header_Bottom';

function Index(props) {
    
    useEffect(() => {

    }, [])

    return (
        <>
            <header className="header-area header-style-1 header-height-2">
                {/* <Header_Top /> */}
                {/* <Header_Middle /> */}
                <Header_Bottom {...props} />
            </header>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        number: state.number,
        user: state.user
    };
}

export default connect(mapStateToProps)(Index);