import React, { lazy, Suspense, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router'
import { BrowserRouter, Navigate, useLocation, Routes, Route, Link } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux';
import store from './store/store';
import Protected from './components/layouts/Protected'
import Public from './components/layouts/Public'
import { Axios, AxiosUser, AxiosErrorHandling } from "./connection/Axios"
import { connect } from 'react-redux';
// import Users_List from './components/pages/users/List';
// import Users_New from './components/pages/users/New';
import PropagateLoader from "react-spinners/PropagateLoader";
import ScrollToTop from "react-scroll-to-top";
import { ToastContainer, toast } from 'react-toastify';

// import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';


import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import './App.css';

const Home = lazy(() => import('./components/home/Index'));
const Test = lazy(() => import('./components/home/Test'));
const Register = lazy(() => import('./components/auth/Register'));
const Login = lazy(() => import('./components/auth/Login'));
const ResetPasswordStep1 = lazy(() => import('./components/auth/ResetPasswordStep1'));
const ResetPasswordStep2 = lazy(() => import('./components/auth/ResetPasswordStep2'));
const Logout = lazy(() => import('./components/auth/Logout'));
const Dashboard = lazy(() => import('./components/dashboard/Index'));
const Product_Details = lazy(() => import('./components/products/Details'));
const Cart = lazy(() => import('./components/cart/Index'));
const BankResult = lazy(() => import('./components/cart/BankResult'));
const Category = lazy(() => import('./components/pages/Category'));
const Article = lazy(() => import('./components/pages/Article'));

const Base = lazy(() => import('./components/user/Base'));
const Profile = lazy(() => import('./components/user/Profile'));
const Edit = lazy(() => import('./components/user/Edit'));
const ChangePassword = lazy(() => import('./components/user/ChangePassword'));
const Favorites = lazy(() => import('./components/user/Favorites'));
const Tracking = lazy(() => import('./components/user/Tracking'));
const Addresses = lazy(() => import('./components/user/Addresses'));
const Orders = lazy(() => import('./components/user/Orders'));
const Order_Details = lazy(() => import('./components/user/Order_Details'));

function App(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const state = store.getState()
    const params = useParams();
    // const [checker,setChecker] = useState(false);
    // console.log('params:', params)
    // console.log('location:', location)

    const baseLoading = useSelector(state => state.baseLoading)
    const loading = useSelector(state => state.loading)

    const routes = [
        {
            id: 'home',
            path: '/',
            // element: 'ورود',
            type: 'public',
            component: <Home />
        },
        {
            id: 'home2',
            path: '/home',
            // element: 'ورود',
            type: 'public',
            component: <Home />
        },
        {
            id: 'test',
            path: '/test',
            // element: 'ورود',
            type: 'public',
            component: <Test />
        },
        {
            id: 'login',
            path: '/login',
            // element: 'ورود',
            type: 'public',
            component: <Login />
        },
        {
            id: 'logout',
            path: '/logout',
            type: 'public',
            component: <Logout />
        },
        {
            id: 'logout',
            path: '/reset-password',
            type: 'public',
            component: <ResetPasswordStep1 />
        },
        {
            id: 'logout',
            path: '/reset-password/:token',
            type: 'public',
            component: <ResetPasswordStep2 />
        },
        // {
        //     id: 'register',
        //     path: '/register',
        //     // element: 'ورود',
        //     type: 'public',
        //     component: <Register />
        // },
        {
            id: 'p',
            path: '/p/:slug',
            type: 'public',
            component: <Product_Details />
        },
        {
            id: 'c',
            path: '/c/:slug',
            type: 'public',
            component: <Category />
        },
        {
            id: 'a',
            path: '/a/:slug',
            type: 'public',
            component: <Article />
        },
        {
            id: 'dashboard',
            path: '/dashboard',
            type: 'protected',
            component: <Dashboard />
        },
        {
            id: 'user-profile',
            path: '/user/profile',
            type: 'protected',
            component: <Base element={<Profile />} />
        },
        {
            id: 'user-favorites',
            path: '/user/favorites',
            type: 'protected',
            component: <Base element={<Favorites />} />
        },
        {
            id: 'user-tarcking',
            path: '/user/tracking',
            type: 'protected',
            component: <Base element={<Tracking />} />
        },
        {
            id: 'user-addresses',
            path: '/user/addresses',
            type: 'protected',
            component: <Base element={<Addresses />} />
        },
        {
            id: 'user-orders',
            path: '/user/orders',
            type: 'protected',
            component: <Base element={<Orders />} />
        },
        {
            id: 'user-order-details',
            path: '/user/orders/:code',
            type: 'protected',
            component: <Base element={<Order_Details />} />
        },
        {
            id: 'user-edit',
            path: '/user/edit',
            type: 'protected',
            component: <Base element={<Edit />} />
        },
        {
            id: 'user-change-password',
            path: '/user/change-password',
            type: 'protected',
            component: <Base element={<ChangePassword />} />
        },
        {
            id: 'cart',
            path: '/user/cart',
            type: 'protected',
            component: <Cart />
        },
        {
            id: 'cart2',
            path: '/user/cart/:paymentCode',
            type: 'protected',
            component: <Cart />
        },
        {
            id: 'cart3',
            path: '/user/cart/verify/:orderId',
            type: 'protected',
            component: <BankResult />
        },
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
        // if(location?.pathname !== '/')
        //     getContent()
    }, [location])

    useEffect(() => {
        store.dispatch({ type: 'START_BASELOADING'});
        getData()
        checkToken()
    }, [])

    const checkToken = () => {
        if(state?.logedIn) {

        } else if(localStorage.getItem('token')) {
            // console.log('App - checkToken.')
            let token = localStorage.getItem('token')
            if(token !== null && token !== undefined) {
                AxiosUser.post('/check-token', JSON.stringify({token: token, type: 'silent', timezone: Intl.DateTimeFormat().resolvedOptions().timeZone}))
                .then(async (res) => {
                    let data = res.data
                    // console.log('checkToken - data:',data)
                    let userInfo = {
                        id: data.data.id,
                        code: data.data.code,
                        gender: data.data.gender,
                        name: data.data.name,
                        last_name: data.data.last_name,
                        email: data.data.email,
                        mobile: data.data.mobile,
                        national_id: data.data.national_id,
                        avatar: data.data.avatar,
                        cart: data.data.cart,
                        favorites: data.data.favorites,
                        orders: data.data.orders,
                        token: token
                    }
                    store.dispatch({ type: 'LOGIN', payload: userInfo });
                })
                .catch((err) => {
                    // AxiosErrorHandling(err,location)
                })
            }
        }
    }

    const getData = () => {
        Axios.get('/home')
        .then(async (res) => {
            let data = res.data
            let home = data.data

            store.dispatch({ type: 'HOME', payload: {home: home} });
        })
        .catch((err) => {
            // console.log('err^^^^:',err)
            // AxiosErrorHandling(err,location)
        })
        .finally(() => {
            store.dispatch({ type: 'STOP_BASELOADING'});
            // setChecker(true)
        })
    }

    const getContent = () => {
        Axios.get('/c/aaaa')
        .then(async (res) => {
            let data = res.data
            // console.log('getContent data:',data)
            // let home = data.data

            // store.dispatch({ type: 'HOME', payload: {home:home} });
        })
        .catch((err) => {
            AxiosErrorHandling(err,location)
        })
    }

    // console.log('baseLoading:',baseLoading)
    // console.log('loading:',loading)

    if(baseLoading)
        return (
            <div style={{width:'100vw',height:'100vh',paddingTop:'30%', backgroundColor:'#E5D283'}}>
                <PropagateLoader color="#1D539E" className="d-flex aligns-items-center justify-content-center" />
            </div>
        )

    // if(loading)
    //     return (<PropagateLoader color="#00FF00" className="d-flex aligns-items-center justify-content-center" />)
    // else
    return (
        // <Provider store={store}>
        <>
            <Suspense fallback={<PropagateLoader color="#000000" className="d-flex aligns-items-center justify-content-center" />}>
            <Routes>
                {/* <Route path="/" element={
                    <>
                        Landing page<br/>
                        <Link to="/login" >Login</Link>
                    </>
                } /> */}
                {/* <Route path="/" element={<Home redirectTo={location} />} />
                <Route path="/login" element={<Login redirectTo={location} />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/test" element={<Test />} /> */}

                {
                    routes.filter(route => route.type === 'public').map(route => {
                        if(route.component)
                            return (
                                <Route
                                    exact
                                    key={route.id}
                                    path={route.path}
                                    element={
                                    <Public redirectTo={location} >
                                        {route.component}
                                    </Public>
                                    }
                                />
                            )
                    })
                }
                {
                    routes.filter(route => route.type === 'protected').map(route => {
                        return (
                            <Route
                                key={route.id}
                                path={route.path}
                                element={
                                <Protected redirectTo={location} >
                                    {route.component}
                                </Protected>
                                }
                            />
                        )
                    })
                }

                {/* <Route path="/test" element={<Test />}></Route> */}
                {/* <Route path="/bank" element={
                    <>
                        <div style={{margin:'100px auto', width:'200px', backgroundColor:'yellow', textAlign:'center', lineHeight:'50px'}}>
                            <a href="/user/cart/AAAA">
                            بازگشت به فروشگاه
                            </a>
                        </div>
                    </>
                }></Route> */}
                <Route path="/500" element={<>500</>}></Route>
                <Route path="*" element={<>404</>}></Route>
            </Routes>
            <ToastContainer
                position="top-left"
                autoClose={10000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{marginTop:'100px'}}
            />
            </Suspense>
            <ScrollToTop smooth top={200} style={{left:'40px',right:'unset'}} />
        </>
        // </Provider>
    );
}

export default App;
