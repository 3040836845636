import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import store from '../../store/store'
import { Axios, AxiosUser, AxiosErrorHandling } from "../../connection/Axios"
import * as IconsBi from 'react-icons/bi';

function MiniCart(props) {
    const state = store.getState()
    // console.log('In_Header - state:',state,state.user?.cart)
    const cart = state.user?.cart
    const [shake,setShake] = useState(false)

    useEffect(() => {

    }, [])

    useEffect(() => {
        setShake(true)
        const timeoutID = window.setTimeout(() => {
            setShake(false)
        }, 1000);
    
        return () => window.clearTimeout(timeoutID );
    }, [state.user?.cart])

    const handleDelete = (sku) => {
        let data = {
            sku: sku
        }
        AxiosUser.post('/carts/delete', data)
        .then(async (res) => {
            let data = res.data
            console.log('data:',data)
            props.dispatch({ type: 'CART_UPDATE', payload: {cart: data.data} });
            // setProduct({
            //     ...product,
            //     [type]: !product[type]
            // })
        })
        .catch((err) => {
            // AxiosErrorHandling(err)
        })
    }

    return (
        <>
            <Link className={shake ? "mini-cart-icon shake_wrapper" : ""} to="">
                {/* <div className=""> */}
                    <IconsBi.BiBasket className="mr-10" />
                    {
                        parseInt(cart?.quantity) > 0 &&
                        <span className="pro-count blue">
                            {cart?.quantity || 0}
                        </span>
                    }
                {/* </div> */}
            </Link>
            <div className="cart-dropdown-wrap cart-dropdown-hm2" dir="rtl">
                {
                    cart?.items?.length > 0
                    ?
                    <>
                        <ul>
                            {
                                cart?.items?.map((item,index) => {
                                    return (
                                        <li key={index}>
                                            {/* <div className="shopping-cart-img">
                                                <Link to={item.slug}>
                                                    <img alt="Nest" src="/assets/imgs/shop/thumbnail-3.jpg" />
                                                </Link>
                                            </div> */}
                                            <div className="shopping-cart-title">
                                                <h4>
                                                    <Link to={"/p/"+item.slug}>
                                                        {item.title}
                                                    </Link>
                                                </h4>
                                                <h4><span>{item.quantity} × </span>{item.unit_price}</h4>
                                            </div>
                                            <div className="shopping-cart-delete">
                                                <a title="حذف از سبد" style={{fontSize:'12px'}} onClick={() => {handleDelete(item.sku)}}>
                                                    {/* <IconsBi.BiX style={{color:'red'}} /> */}
                                                    <span style={{border:'1px solid #eee',padding:'5px'}}>
                                                    حذف
                                                    </span>
                                                </a>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className="shopping-cart-footer">
                            <div className="shopping-cart-total">
                                <h4>جمع کل <span>{cart?.amount} تومان</span></h4>
                            </div>
                            <div className="shopping-cart-button">
                                <Link to={"/user/cart"} className="outline">مشاهده سبد خرید</Link>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <p className="text-center">سبد شما خالیست</p>
                    </>
                }
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        number: state.number,
        user: state.user
    };
}

export default connect(mapStateToProps)(MiniCart);
