import axios from "axios";
import { useNavigate } from 'react-router'
import { Navigate, useLocation, matchRoutes, Link } from 'react-router-dom'
import store from './../store/store';
import { toast } from 'react-toastify';

const lang = localStorage.getItem('lang')
// const navigate = useNavigate();

// export const AxiosBaseURL = "http://localhost/Store/store-backend/public/api/"
export const AxiosBaseURL = "https://shop-backend.kiakampharmed.com/api/"

export const Axios = axios.create({
    baseURL: AxiosBaseURL,
    timeout: 120000,
    headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json",
        // 'Access-Control-Max-Age':'360000',
        'Access-Control-Allow-Origin':'*',
        // 'Authorization': localStorage.getItem('token') ? "Bearer " + localStorage.getItem('token') : '',
        'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
})

export const AxiosUser = axios.create({
    baseURL: AxiosBaseURL + 'user/',
    timeout: 120000,
    headers: {
        'Accept': 'application/json',
        "Content-Type": "application/json",
        // 'Access-Control-Max-Age':'360000',
        'Access-Control-Allow-Origin':'*',
        'Authorization': localStorage.getItem('token') ? "Bearer " + localStorage.getItem('token') : '',
        'timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    }
})

AxiosUser.interceptors.request.use(function (config) {
    store.dispatch({ type: 'START_LOADING'});
    // console.log('AxiosUser.interceptors.request 1')
    // Do something before request is sent
    return config;
}, function (error) {
    // console.log('axios.interceptors.request 2')
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
AxiosUser.interceptors.response.use(function (response) {
    store.dispatch({ type: 'STOP_LOADING'});
    if(response?.data?.message) {
        if(response?.data?.type === 'update_basket') {
            let message = () => (
                <div dir="rtl">
                    <p>{response?.data?.message}</p>
                    <Link to="/user/cart"> مشاهده سبد خرید</Link>
                </div>
            );
            toast.success(message)
        }
        else
            toast.success(response?.data?.message)

    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    AxiosErrorHandling(error)
    // console.log('axios.interceptors.response 2',error)
    // if(error?.response?.data?.message)
    //     toast.error(error?.response?.data?.message)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });

// export default Axios;


// export function AxiosErrorHandling(error, setServerErrors, flashMessageData, setFlashMessageData, setAccessDenied) {
export function AxiosErrorHandling(error,location) {
    // const location = useLocation()
    // console.log('location:',location)
    // console.log('error:',error,location)
    location = location || null

    // console.log('error.response:',error.response,location)
    // console.log('error.response.status:',error.response.status)
    // console.log('error:',error.response.data)
    // console.log('error:',error.response.data.message)

    if(parseInt(error.response.status) === 401) {
        // console.log('HERE 401')
        // let message = '.لطفا وارد حساب کاربریتان شوید' + '<a href="/login">فرم ورود به حساب کاربری</a>'
        const message = () => (
            <div dir="rtl">
                <p>.لطفا وارد حساب کاربریتان شوید</p>
                <Link to="/login">فرم ورود به حساب کاربری</Link>
            </div>
        );
        toast.error(message)
        // localStorage.removeItem('token')
        if(location !== null && location?.pathname !== '/login') {
            // window.location.href = '/login';
        }

        // const { pathname } = location;
        // console.log('location:',location)
        // const [{ route }] = matchRoutes(routes, location)
    } else if(parseInt(error.response.status) === 500) {
        // console.log('HERE 500')
        toast.error(<div>خطای سروری!<br/>{error.response?.data?.message}</div>)
    } else if(parseInt(error.response.status) === 404) {
        console.log('HERE 404')
        toast.error(<div>آدرس نامعتبر!<br/>شما به صفحه اصلی منتقل میشوید.</div>)
        // navigate("/home");

    }



    // if(error.response.status === 500) {
    //     let message = ''//dictionary.message500
    //     if(error?.response?.message !== undefined && error?.response?.message !== '')
    //         message = message + '\n' + error.response.message

    //     setFlashMessageData([...flashMessageData, {message:message,messageStatus:'danger', duration: 10}])
    // }
    // if(error.response.status === 401)
    //     setAccessDenied(true)
    // else {
    //     if(error.response.data?.displayType === 'external')
    //         setAccessDenied(true)
    //     else {
    //         setServerErrors(error.response.data)
    //     }
    // }
    // console.log('AxiosErrorHandling - start')
    // console.error({ error });
    if (error.response) {
        // console.log('case 1')
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // console.log(error.response.data);
        // console.log(error.response.data.status);
        // console.log(error.response.headers);
    } else if (error.request) {
        // console.log('case 2')
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // console.log(error.request);
    } else {
        // console.log('case 3')
        // Something happened in setting up the request that triggered an Error
        // console.log('Error', error.message);
    }
    // console.log('AxiosErrorHandling - end')
}

export const AxiosConfigFileUpload = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}
