import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import store from './../../store/store'
import * as IconsBi from 'react-icons/bi';
import { Row } from 'react-bootstrap';

function Header_Top(props) {
    const state = store.getState()
    
    useEffect(() => {

    }, [])

    return (
        <>
            {/* <div className="header-top header-top-ptb-1 d-none d-lg-block"> */}
            <div className="header-top header-top-ptb-1">
                {/* <div className="container"> */}
                <Row className="mx-3">
                    <div className="row align-items-center">
                        <div className="col-xl-9 col-lg-9">
                            <div className="header-info">
                                <ul>
                                    {
                                        (state.user && state.user?.id !== null)
                                        ?
                                            <>
                                                <li>
                                                    <span style={{color:'red'}}>
                                                    { state.user?.name + " " + "عزیز خوش آمدی" }
                                                    </span>
                                                </li>
                                                <li>
                                                    <Link to="/user/profile">
                                                        حساب کاربری من
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/user/favorites">
                                                        علاقمندی های من
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="/user/tracking">
                                                        پیگیری سفارش
                                                    </Link>
                                                </li>
                                            </>
                                        :
                                        <>
                                            <li>
                                                <Link to="/login">
                                                    ورود
                                                </Link>
                                            </li>
                                            {/* <li>
                                                <Link to="/register">
                                                    ایجاد حساب کاربری
                                                </Link>
                                            </li> */}
                                        </>
                                    }
                                    <li>
                                        <Link to="/a/about-us">
                                        درباره ما
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 d-none d-lg-block">
                            <div className="header-info header-info-right">
                                <ul>
                                    <li>پشتیبانی: <strong className="text-brand" dir="ltr"> +21 2629 0017 </strong></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Row>
                {/* </div> */}
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        number: state.number,
        user: state.user
    };
}

export default connect(mapStateToProps)(Header_Top);