import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import store from './../../store/store'
import * as IconsBi from 'react-icons/bi';
import * as IconsGr from "react-icons/gr";

function Index(props) {
    const state = store.getState()
    // console.log('props***:',props,props?.state?.home?.articles)

    useEffect(() => {

    }, [])

    return (
        <>
            <footer className="main">
                {/* <section className="newsletter mb-15 wow animate__animated animate__fadeIn">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="position-relative newsletter-inner">
                                    <div className="newsletter-content">
                                        <h2 className="mb-20">
                                            Stay home & get your daily <br />
                                            needs from our shop
                                        </h2>
                                        <p className="mb-45">Start You'r Daily Shopping with <span className="text-brand">Nest Mart</span></p>
                                        <form className="form-subcriber d-flex">
                                            <input type="email" placeholder="Your emaill address" />
                                            <button className="btn" type="submit">Subscribe</button>
                                        </form>
                                    </div>
                                    <img src="/assets/imgs/banner/banner-9.png" alt="newsletter" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="featured section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-1-5 col-md-4 col-12 col-sm-6 mb-md-4 mb-xl-0 mb-2">
                                <div className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp h-100" data-wow-delay="0">
                                    <div className="banner-icon">
                                        <img src="/assets/imgs/theme/icons/icon-1.svg" alt="" />
                                    </div>
                                    <div className="banner-text">
                                        <h3 className="icon-box-title">بیشترین تخفیف</h3>
                                        <p>تا ۳۰ درصد تخفیف سازمانی</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1-5 col-md-4 col-12 col-sm-6 mb-2">
                                <div className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp h-100" data-wow-delay=".1s">
                                    <div className="banner-icon">
                                        <img src="/assets/imgs/theme/icons/icon-2.svg" alt="" />
                                    </div>
                                    <div className="banner-text">
                                        <h3 className="icon-box-title">ارسال رایگان</h3>
                                        <p>تحویل در محل کار</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1-5 col-md-4 col-12 col-sm-6 mb-2">
                                <div className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp h-100" data-wow-delay=".2s">
                                    <div className="banner-icon">
                                        <img src="/assets/imgs/theme/icons/icon-3.svg" alt="" />
                                    </div>
                                    <div className="banner-text">
                                        <h3 className="icon-box-title">بهترین قیمت</h3>
                                        <p>مطابق با قیمتهای مصوب</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-1-5 col-md-4 col-12 col-sm-6 mb-2">
                                <div className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp h-100" data-wow-delay=".3s">
                                    <div className="banner-icon">
                                        <img src="/assets/imgs/theme/icons/icon-4.svg" alt="" />
                                    </div>
                                    <div className="banner-text">
                                        <h3 className="icon-box-title">دسترسی آسان</h3>
                                        <p>انتخاب و خرید راحت </p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-1-5 col-md-4 col-12 col-sm-6">
                                <div className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                    <div className="banner-icon">
                                        <img src="/assets/imgs/theme/icons/icon-5.svg" alt="" />
                                    </div>
                                    <div className="banner-text">
                                        <h3 className="icon-box-title">ضمانت کیفیت</h3>
                                        <p>Within 30 days</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-1-5 col-md-4 col-12 col-sm-6 mb-2">
                                <div className="banner-left-icon d-flex align-items-center wow animate__animated animate__fadeInUp h-100" data-wow-delay=".5s">
                                    <div className="banner-icon">
                                        <img src="/assets/imgs/theme/icons/icon-6.svg" alt="" />
                                    </div>
                                    <div className="banner-text">
                                        <h3 className="icon-box-title">ضمانت کیفیت</h3>
                                        <p>گارانتی سلامت و کیفیت کالا</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section-padding footer-mid">
                    <div className="container pt-15 pb-20">
                        <div className="row">
                            <div className="col">
                                <div className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                    <div className="logo mb-30 text-center">
                                        <a href="index.html" className="mb-15"><img src="/assets/imgs/theme/logo.png" alt="logo" style={{height:'120px'}} /></a>
                                        <p className="font-lg text-heading" style={{fontWeight:'bold'}}>شرکت کیاکام فارمد</p>
                                    </div>
                                    <ul className="contact-infor">
                                        <li><img src="/assets/imgs/theme/icons/icon-location.svg" alt="" /><span>تهران، میدان ونک، خیابان عطار، پلاک ۵۰</span></li>
                                        <li><img src="/assets/imgs/theme/icons/icon-contact.svg" alt="" /><span dir="ltr" className="text-right">(+98) 21 2629 0017</span></li>
                                        <li><img src="/assets/imgs/theme/icons/icon-email-2.svg" alt="" /><span>info@kiakampharmed.com</span></li>
                                        <li><img src="/assets/imgs/theme/icons/icon-clock.svg" alt="" /><span>شنبه تا چهارشنبه 08:00 - 16:00</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                                <h5 className="widget-title">لینک های مرتبط</h5>
                                <ul className="footer-list mb-sm-5 mb-md-0">
                                    {
                                        props?.state?.home?.articles?.map((item,index) => (
                                            <li key={index}>
                                                <Link to={"/a/" + item.slug}>
                                                    {item.title}
                                                </Link>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                <h5 className="widget-title">منوی کاربری</h5>
                                <ul className="footer-list mb-sm-5 mb-md-0">
                                    <li>
                                        <Link to="/user/profile">
                                            حساب کاربری من
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/user/tracking">
                                            پیگیری سفارش
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/user/orders">
                                        سفارش‌های من
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/user/favorites">
                                        علاقمندی‌های من
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-link-widget col">
                                <h5 className="widget-title">شبکه های اجتماعی</h5>
                                <ul className="footer-list mb-sm-5 mb-md-0" dir="ltr" style={{paddingLeft:'30px'}}>
                                    <li>
                                        <a style={{fontSize:'25px'}} href="https://www.linkedin.com/company/kia-kam-pharmed" target="_blank">
                                            <IconsGr.GrLinkedin style={{marginRight:'10px', color:'#3BB77E'}} />
                                            <span style={{fontSize:'16px'}}>Kia Kam Pharmed</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a style={{fontSize:'25px'}} href="https://www.instagram.com/cyspersa" target="_blank">
                                            <IconsGr.GrInstagram style={{marginRight:'10px', color:'#3BB77E'}} />
                                            <span style={{fontSize:'16px'}}>Cyspersa</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a style={{fontSize:'25px'}} href="https://www.instagram.com/brightmax.ir" target="_blank">
                                            <IconsGr.GrInstagram style={{marginRight:'10px', color:'#3BB77E'}} />
                                            <span style={{fontSize:'16px'}}>Brightmax</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a style={{fontSize:'25px'}} href="https://www.instagram.com/cicalderm" target="_blank">
                                            <IconsGr.GrInstagram style={{marginRight:'10px', color:'#3BB77E'}} />
                                            <span style={{fontSize:'16px'}}>Cicalderm</span>
                                        </a>
                                    </li>
                                    <li>
                                        <a style={{fontSize:'25px'}} href="https://www.instagram.com/elio_ir" target="_blank">
                                            <IconsGr.GrInstagram style={{marginRight:'10px', color:'#3BB77E'}} />
                                            <span style={{fontSize:'16px'}}>Elio</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-link-widget col">
                                <a referrerPolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=439812&Code=6ZEPrnm6FJRV1ZuvF59pgdRXXHrKsK68'>
                                    <img referrerPolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=439812&Code=6ZEPrnm6FJRV1ZuvF59pgdRXXHrKsK68' style={{cursor:'pointer'}} code='6ZEPrnm6FJRV1ZuvF59pgdRXXHrKsK68' alt="لوگوی اینماد" />
                                </a>
                            </div>
                            {/* <div className="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                <h5 className="widget-title">پر طرفدارها</h5>
                                <ul className="footer-list mb-sm-5 mb-md-0">
                                    <li><a href="#">Milk & Flavoured Milk</a></li>
                                    <li><a href="#">Butter and Margarine</a></li>
                                    <li><a href="#">Eggs Substitutes</a></li>
                                    <li><a href="#">Marmalades</a></li>
                                    <li><a href="#">Sour Cream and Dips</a></li>
                                    <li><a href="#">Tea & Kombucha</a></li>
                                    <li><a href="#">Cheese</a></li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </section>

                <div className="container pb-30 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                    <div className="row align-items-center">
                        {/* <div className="col-xl-4 col-lg-6 col-md-6"> */}
                            <p className="font-sm mb-0 text-center">
                                <span>تمامی حقوق این فروشگاه برای شرکت کیاکام فارمد محفوظ است. </span>
                                &copy; {new Date().toLocaleString("fa-IR",{ year: "numeric"})}</p>
                        {/* </div> */}
                    </div>
                </div>
            </footer>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        number: state.number,
        user: state.user
    };
}

export default connect(mapStateToProps)(Index);
